<template>
  <div>
    <div class="modalPopup user_report_modal">
      <div>
        <div class="modal_header">
          <h3 class="title">사용자 이용현황</h3>
          <button class="modal_close" @click="$emit('onclose')"></button>
        </div>
      </div>
      <div class="modal_body">
        <div class="excel_wrap">
          <button class="excel_btn" @click="exportExcel()">엑셀로 저장</button>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>이름</th>
                <th>대 메뉴</th>
                <th>서브 메뉴</th>
                <th>이용 기능</th>
                <th>일자</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="action in user_actions" :key="action.id">
                <td>{{ getAccountFromUserId(action.user_id) }}</td>
                <td>
                  {{
                    action.menu_group_id != null
                      ? getTopMenuNameFromMenuId(action.menu_group_id)
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    action.menu_group_id != null
                      ? getSubMenuNameFromMenuId(action.menu_group_id)
                      : '-'
                  }}
                </td>
                <td>
                  {{ getActionNameFromTypeId(action.user_action_type_id) }}
                </td>
                <td>{{ action.create_time | formatDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginate_wrap">
          <button
            type="button"
            :disabled="pageNum === 0"
            @click="prevPage"
            class="prev_btn"
          >
            이전
          </button>
          <span class="page-count">{{ pageNum + 1 }} / {{ pageCount }}</span>
          <button
            type="button"
            :disabled="pageNum >= pageCount - 1"
            @click="nextPage"
            class="next_btn"
          >
            다음
          </button>
        </div>
      </div>
      <div class="modal_footer">
        <button type="button" class="btn_sub2" @click="$emit('onclose')">
          닫기
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import ModalMixin from '@/mixins/modal';
export default {
  mixins: [ModalMixin],
  data() {
    return {
      pageNum: 0,
    };
  },
  props: {
    listArray: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  computed: {
    ...mapGetters({
      menuItems: 'getMenuItems',
      users: 'getUsersTempFromUserPage',
      actionType: 'getUserActionType',
      user_actions: 'getUserAction',
      start_date: 'getStartDateFromUserActionPage',
      end_date: 'getEndDateFromUserActionPage',
    }),
    pageCount() {
      let listLeng = this.listArray.length,
        listSize = this.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;

      return page;
    },
    user_actions() {
      const start = this.pageNum * this.pageSize,
        end = start + this.pageSize;

      return this.listArray.slice(start, end);
    },
  },
  methods: {
    getAccountFromUserId(id) {
      return this.users.find(x => x.id == id).name || '';
    },
    getTopMenuNameFromMenuId(id) {
      return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)).name;
    },
    getSubMenuNameFromMenuId(id) {
      return this.menuItems
        .find(x => x.sub_menu.find(y => y.id == id))
        .sub_menu.find(x => x.id == id).name;
    },
    getActionNameFromTypeId(id) {
      return this.actionType.find(x => x.id == id).name;
    },
    nextPage() {
      this.pageNum += 1;
    },
    prevPage() {
      this.pageNum -= 1;
    },
    exportExcel() {
      let userReport = [];

      const date = this.start_date + ' ~ ' + this.end_date;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];

      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];

      userReport = userReport.concat(standardInfo);

      userReport.push([
        '사용자명',
        '대 메뉴',
        '서브 메뉴',
        '이용 가능',
        '일자',
      ]);

      this.listArray.forEach(el => {
        userReport.push([
          this.getAccountFromUserId(el.user_id), //아이디
          el.menu_group_id != null
            ? this.getTopMenuNameFromMenuId(el.menu_group_id)
            : '-', //대메뉴
          el.menu_group_id != null
            ? this.getSubMenuNameFromMenuId(el.menu_group_id)
            : '-', //서브메뉴
          this.getActionNameFromTypeId(el.user_action_type_id), //이용가능
          el.create_time.substr(0, 16), //일자
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(userReport, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '사용자 이용현황 목록');
      let title = '사용자 이용현황 목록(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  watch: {
    listArray() {
      this.pageNum = 0;
    },
  },
};
</script>

<style scoped></style>
